<template>
  <div id="adv-widget">
    <button class="adv-button" @click="showChatbot">
      <img
        alt="Vue logo"
        src="https://www.lasegura.com/wp-content/uploads/2021/09/AIVA.png"
      />
    </button>
    <PaymentModal v-if="modalVisible" v-on:toggleModal="togglePaymentModal" />
  </div>
</template>

<script>
import PaymentModal from "./components/PaymentModal.vue";
import chatbotInstance from "./modules/ChatbotInstance";

export default {
  name: "App",
  components: { PaymentModal },
  data() {
    return {
      modalVisible: false,
    };
  },
  created() {
    chatbotInstance.initialize();
    //this.wakeupMiddleware();
  },
 
  methods: {
    showChatbot() {
      chatbotInstance.show();
    },
    togglePaymentModal() {
      this.modalVisible = !this.modalVisible;
    },
   
  },
};
</script>

<style lang="css" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.adv-button {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 0;
  right: 80px;
  border: none;
  cursor: pointer;
}
</style>
