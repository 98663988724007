<template>
  <div class="adv-modal">
    <div class="modal-wrap">
      <div class="modal-exit">
        <button @click="exitModal">X</button>
      </div>
      <iframe  class="payment-frame" @load="onIFrameChange" @error="onIFrameError"
        src="https://demo.tranzpay.com/?p=13">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentModal',
  props: ["toggleModal"],
  data() {
    return {
      counter: 0 //discard modal after second redirect
    }
  },
  watch: {
    counter(next) {
      if(next == 2) {
        this.$emit("toggleModal")
      }
    }
  },
  methods: {
    onIFrameChange () {
      this.counter++;
    }
  }
}
</script>

<style lang="css" scoped>
.adv-modal {
  position: absolute;
  background-color: black;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-wrap {
  position: relative;
  width: 1280px;
  height: 1024px;
  border: none;
}

.modal-exit {
  align-self: flex-end;
}

.payment-frame {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
