
import axios from "axios";

// const getImageUrl = function () {
//   console.log(`/assets/images/test.jpg`)
//   return new URL('https://www.lasegura.com/wp-content/uploads/2021/09/AIVA.png')
// }

const buildOptions = (opts) => {
  return {
    chatPanel: {
      messageList: {
      BackgroundImageUrl: "https://www.lasegura.com/wp-content/uploads/2021/09/AIVA.png",
      },
      header: {
        title: `${opts.title || "Insurance Chatbot"}`,
      },
      styling: {
        avatarShape: "rounded",
        avatarUrl: "https://www.lasegura.com/wp-content/uploads/2021/09/AIVA.png",
        panelShape: "squared",

        panelImageUrl:"https://www.lasegura.com/wp-content/uploads/2021/09/AIVA.png",
        panelScrollbarColor: opts.panelScrollbarColor || "#636569",
        contrastColor: opts.contrastColor || "#00b67a",
        primaryColor: opts.primaryColor || "#014880",
        pace: "supersonic",
        composer: {
          sendButtonColor: { backgroundColor: "#9b9fa8" },
        },
      },
    },
  };
}

const  config =  {
 
  headers: {
    'Content-Type': 'application/json',
     'Ocp-Apim-Subscription-Key': '785b6360b7d644ae954cf5725da17d2e', 
  }
  
};

function ChatbotInstance() {
  this.initialize = () => {
    axios.get(`https://advanciodatahubapi.azure-api.net/ChatBotSettings/GetChatBotSettings?HostName=${window.location.hostname}`, config).then((res) => {
      if (window.boostInit) {
        const options = buildOptions(JSON.parse(res.data.ChatBotOptions) || {})
        this.chatbot = window.boostInit("partner102", options);

      }
     
    })
    .catch((error)=> {
      console.log({error})
      const options = buildOptions({})
      this.chatbot = window.boostInit("partner102", options);
    })
  };
  this.show = () => {
    this.chatbot.chatPanel.show();
  };
}



const chatbotInstance = new ChatbotInstance();
export default chatbotInstance;
